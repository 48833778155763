<template>
  <div class="notice-reset-password">
    <h1 class="notice-reset-password__title">
      Мы отправили инструкцию вам на почту. Проверьте.
    </h1>
    <v-button :to="{ name: 'login-staff' }" primary>Понятно</v-button>
  </div>
</template>

<script>
import VButton from '@/components/common/VButton.vue'

export default {
  name: 'NoticeResetPassword',
  components: {
    VButton
  }
}
</script>
